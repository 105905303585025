<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">开票信息</a>
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list" class="active">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!-- modify mao 20250324 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!--  -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">
            开票信息
            <a href="javascript:;" class="add_certificate fs16 ml0" @click="closePop()">新增开票信息</a>
          </div>
          <div v-if="cert_list.length == 0 && isShowNavBarLoading == true">
            <div class="no_data">
              <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div v-else>
            <ul class="address_list fs16">
              <li v-for="(v, k) in cert_list" :key="k">
                <div class="default" v-if="v.defaultFlag == 1">默认</div>
                <img src="../assets/static/icon/icon_close.png" @click="del_cert(v.id, k)" :data-id="v.id"
                  class="icon_close" alt="" />
                <div class="flex-box edit_btn fs16" @click="edit(v, k)">编辑</div>
                <div class="pb20">
                  <span class="fs16 col9">发票抬头：</span>
                  <span class="fs16 col10">{{ v.title }}</span>
                </div>
                <div class="pb20">
                  <span class="fs16 col9">纳税识别号：</span>
                  <span class="fs16 col10">{{ v.texNo }}</span>
                </div>
                <div class="pb20">
                  <span class="fs16 col9">详细地址：</span>
                  <span class="fs16 col10">{{ v.address }}</span>
                </div>
                <div class="pb20">
                  <span class="fs16 col9">联系电话：</span>
                  <span class="fs16 col10">{{ v.tel }}</span>
                </div>
                <div class="pb20">
                  <span class="fs16 col9">开户行：</span>
                  <span class="fs16 col10">{{ v.bankName }}</span>
                </div>
                <div class="pb20">
                  <span class="fs16 col9">银行账号：</span>
                  <span class="fs16 col10">{{ v.account }}</span>
                </div>
              </li>
            </ul>
            <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加|删除 -->
    <Popup :visible="popup" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">{{ cert }}</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop()" alt="" />
        </div>
        <div class="cirticle_info">

          <el-form :model="current" :rules="rules" ref="form">
          <el-form-item prop="title" ref="form">
              <div class="must fs16 col9 info_title">发票抬头：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.title" placeholder="请输入发票抬头" />
          </el-form-item>
          <el-form-item prop="texNo" ref="form">
              <div class="must fs16 col9 info_title">纳税识别号：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.texNo" placeholder="请输入纳税识别号" />
          </el-form-item>
          <el-form-item prop="address" ref="form">
              <div class="must fs16 col9 info_title">详细地址：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.address" placeholder="请输入详细地址" />
          </el-form-item>
          <el-form-item prop="tel" ref="form">
              <div class="must fs16 col9 info_title">联系电话：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.tel" placeholder="请输入联系电话" />
          </el-form-item>

          <el-form-item prop="bankName" ref="form">
              <div class="must fs16 col9 info_title">开户行：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.bankName" placeholder="请输入开户行" />
          </el-form-item>
          <el-form-item prop="account" ref="form">
              <div class="must fs16 col9 info_title">银行账号：</div>
              <el-input type="text" class="invoice_input fs16" v-model="current.account" placeholder="请输入银行账号" />
          </el-form-item>
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认发票信息</div>
            <div class="switch" :class="current.defaultFlag == '1' ? 'on' : ''" @click="setDefault()"></div>
          </div>
          <div class="save" @click="submit">提交</div>
        </el-form>
        </div>

      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import "element-ui/lib/theme-chalk/index.css";
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      popup: false,
      cert_list: [],
      pageSize: 10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: false, // 是否加载完
      add_edit: {}, //编辑添加
      cert: "新增发票信息",
      coun_index: "-1", //编辑的下标
      current: {
        title: '',
        texNo: '',
        address: '',
        tel: '',
        bankName: '',
        account: '',
        id: 0,
        defaultFlag:"0" ,//“0”否；“1”是
      },
      rules: {
        title: [{
          required: true,
          message: '请输入发票抬头',
          trigger: ['change', 'blur'],
        }],
        texNo: [{
          required: true,
          message: '请输入纳税识别号',
          trigger: ['change', 'blur'],
        }],

        tel: [{
          required: true,
          message: '请输入联系电话',
          trigger: ['change', 'blur'],
        }],
        address: [{
          required: true,
          message: '请输入详细地址',
          trigger: ['change', 'blur'],
        }],

        bankName: [{
          required: true,
          message: '请输入开户行',
          trigger: ['change', 'blur'],
        }],
        account: [{
          required: true,
          message: '请输入银行账号',
          trigger: ['change', 'blur'],
        }],
      },
    };
  },
  // 页面渲染完执行
  created() {
    let _that = this;
    // 获取抬头
    _that.get_maintain();
  },
  methods: {

    submit() {
      let that = this
      that.$refs['form'].validate(valid => {
        if (valid) {
          let res = that.current
          let user = cookie.get("huanbaoqiao_userinfo");
          let item2 = {
            title: res.title,
            texNo: res.texNo,
            address: res.address,
            tel: res.tel,
            bankName: res.bankName,
            account: res.account,
            userId: user.id,
            defaultFlag:res.defaultFlag
          };
          if (item2.tel != '') {
            var patrn = /^1[3456789]\d{9}$/; //手机电话
            var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
            if (patrn.test(item2.tel) == false && zjdh.test(item2.tel) == false) {
              that.$message.error("请输入正确的联系方式！");
              return;
            }
          }
          if (res.id && res.id > 0) {
            item2.id = res.id
            that.$api.updateInvoiceMessages(item2).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('编辑成功')
                that.popup = false;
                let index = that.coun_index
                let list = that.cert_list
                list[index] = res.root
                that.cert_list = list
                that.coun_index = -1;
                that.current = {
                  title: '',
                  texNo: '',
                  address: '',
                  tel: '',
                  bankName: '',
                  account: '',
                  id: 0,
                  defaultFlag:"0"
                };
                that.cert = "新增发票信息";
              } else {
                that.$message.error(res.resMsg);
              }
            }).catch((err) => {
              console.log(err);
            });
          } else {
            that.$api.addInvoices(item2).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('提交成功')
                that.popup = false;
                that.get_maintain()
              } else {
                that.$message.error(res.resMsg);
              }
            }).catch((err) => {
              console.log(err);
            });
          }
        } else {
          that.$message.error('请完善信息');
        }
      })
    },

    // 添加抬头
    closePop() {
      let _that = this;
      _that.cert = "新增发票信息";
      _that.coun_index = -1;
      _that.popup = !_that.popup;
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      if(_that.current.defaultFlag==0){
         var defaultFlag = 1
      }else{
        var defaultFlag =0
      }
      _that.current.defaultFlag = defaultFlag;
    },
    // 编辑
    edit(data, index) {
      let _that = this;
      _that.current = data;
      _that.cert = "编辑发票信息";
      _that.popup = !_that.popup;
      _that.coun_index = index
    },
    // 删除发票信息
    del_cert(id, index) {
      let that = this;
      MessageBox.confirm("确定要删除嘛?", "温馨提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            that.$api.delInvoices({ id: id }).then((res) => {
              if (res.resCode == 0) {
                Message.success("删除成功");
                let list = that.cert_list;
                list.splice(index, 1);
                that.cert_list = list;
                that.total = that.total - 1;
              } else {
                Message.error("删除失败");
              }
            });
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_maintain();
    },
    // 获取抬头
    get_maintain() {
      var that = this;
      let page = that.pageNum;
      var s_param = {
        page: page,
        limit: that.pageSize,
        sortProp:'defaultFlag',
        // sortType:'DESC'
      }
      this.$api.userInvoices(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            that.cert_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading == true
          that.$message.error(data_res.msg);
        }
      });
    },

    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },
  },
};
</script>
